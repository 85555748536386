import {
	type ActionFunctionArgs,
	type LoaderFunctionArgs,
	json,
	redirect,
} from "@remix-run/node";
import {
	type MetaFunction,
	isRouteErrorResponse,
	useLoaderData,
	useRouteError,
} from "@remix-run/react";
import { useEffect } from "react";
import SignInBox from "~/routes/_index/sign-in-box";
import { serverCookie } from "~/utils/cookies.server";
import { useAppState } from "~/utils/states";
import { fetchFaskesMeta, generateCsrfToken } from "~/utils/utils.server";

// biome-ignore lint/suspicious/noExplicitAny: default value
export const meta: MetaFunction = ({ data }: { data: any }) => {
	if (!data) return;
	return [
		{
			title: `Booking Online ${data.meta.name}`,
		},
	];
};

export const loader = async ({ request }: LoaderFunctionArgs) => {
	const faskesMeta = await fetchFaskesMeta(request);
	if (!faskesMeta) return redirect("https://trustmedis.com");

	return json({
		meta: faskesMeta,
		csrf: generateCsrfToken(),
	});
};

export const action = async ({ request }: ActionFunctionArgs) => {
	const body = await request.formData();
	const next = body.get("next")?.toString() || "/app";

	return redirect(next, {
		headers: {
			"Set-Cookie": await serverCookie.serialize({
				patient: body.get("patient"),
				expiredAt: serverCookie.expires,
			}),
		},
	});
};

export default function BookingHome() {
	const { meta, csrf } = useLoaderData<typeof loader>();
	const { setFaskes, fetchBanners, setCsrf } = useAppState();
	useEffect(() => {
		setFaskes(meta);
		setCsrf(csrf);
		if (meta) {
			fetchBanners();
		}
	}, [meta, csrf, setFaskes, setCsrf, fetchBanners]);

	return (
		<div className="flex flex-row h-full">
			{/* LEFT SECTION */}
			<div
				className="w-full md:w-2/3 lg:w-1/2 flex items-center justify-center h-full"
				style={{
					backgroundImage: "url(/login-background.webp)",
					backgroundSize: "cover",
				}}
			>
				<SignInBox />
			</div>

			{/* RIGHT SECTION */}
			<div className="hidden md:block md:w-1/3 lg:w-1/2">
				<div
					className="h-full bg-cover bg-center"
					style={{ backgroundImage: "url(/login-banner-default.webp)" }}
				/>
			</div>
		</div>
	);
}

export function ErrorBoundary() {
	const error = useRouteError() as Error;

	let response = <pre className="text-red-500">{error.message}</pre>;
	if (isRouteErrorResponse(error)) {
		response = (
			<>
				<pre className="text-red-500">
					{error.data} [{error.status}]
				</pre>
			</>
		);
	}

	return (
		<div className="h-full w-full flex flex-col items-center justify-center">
			<p>Application Error!</p>
			<div className="mt-4">{response}</div>
		</div>
	);
}
